$(window)
    .on('load', function () {
        $('#full-screen-preloader').fadeOut(300);
        $('html,body').css({overflow: 'visible'});
        $('.cocked').removeClass('cocked');
    });

$(function () {

    AOS.init();

    let stickyFloatTop;
    let stickyFloatHeight;
    let stickyTop;
    let stickyHeight;

    function setStickyParams() {
        const $stickyFloat = $('.about__content-sticky-float');
        const $sticky = $('.about__content-sticky');
        if ($stickyFloat.length && $sticky.length) {
            stickyFloatTop = $stickyFloat.offset().top;
            stickyFloatHeight = $stickyFloat.outerHeight();
            stickyTop = $sticky.offset().top;
            stickyHeight = $sticky.outerHeight();
            $stickyFloat.css({opacity: (stickyFloatTop - stickyTop) / (stickyHeight - stickyFloatHeight)})
        }
    }

    setStickyParams();

    const $menu = $('#menu-inner');
    const menuTop = $menu.length ? $menu.offset().top : 0;
    console.log(menuTop);
    const menuHeight = $menu.length ? $menu.outerHeight() : 0;
    console.log(menuHeight);

    $(window)
        .scroll(function () {
            const st = $(this).scrollTop();
            if (st >= menuTop + menuHeight) {
                $menu.addClass('fixed')
            } else {
                $menu.removeClass('fixed')
            }
            if (st >= menuTop + menuHeight + 100) {
                $menu.addClass('show')
            } else {
                $menu.removeClass('show')
            }
            setStickyParams();
        })
        .on('resize', setStickyParams);

    const $expersSlider = $('[data-role="schedule-experts"]');
    if ($expersSlider.length) {
        $expersSlider.slick({
            prevArrow: '<div class="schedule__item-expert-prev"></div>',
            nextArrow: '<div class="schedule__item-expert-next"></div>',
            infinite: false
        });
    }

    $('[data-role="schedule-toggle"]')
        .on('click', function () {
            const $p = $(this).parents('[data-role="schedule-item"]');
            $('.schedule__item-body', $p).toggleClass('hidden');

            return false;
        });

    const $modal = $('#modal');
    const $popup = $('#popup');
    $popup
        .on('click', function (e) {
            e.stopPropagation();
        });
    $('[data-role="show-popup"]')
        .on('click', function () {
            const $target = $($(this).data('target'));
            $target.show();
            $('html,body').css({overflow: 'hidden'});
            $modal.fadeIn(300);

            return false;
        });
    $('[data-role="hide-popup"]')
        .on('click', function () {
            $modal.fadeOut(300, function () {
                $('.popup').hide();
                $('html,body').css({overflow: 'visible'});
            });

            return false;
        });

    console.log('Document ready');
});